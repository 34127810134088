<template>
  <div>
    <div class="lb-mb-15">
      <div class="row justify-content-end align-items-center">
        <div class="col-auto">
          <div class="row row-8 align-items-center">
            <div class="col-auto">
              <div class="btn-group no-bg">
                <button @click="calendarApi.prev()" class="btn btn-ghost btn-icon"><img src="@/assets/img/icons/icon-keyboard_arrow_left.svg" alt="icon"></button>
                <button @click="calendarApi.next()" class="btn btn-ghost btn-icon"><img src="@/assets/img/icons/icon-keyboard_arrow_right.svg" alt="icon"></button>
              </div>
            </div>
            <div class="col-auto">
              <div class="h4 lb-mr-26" v-if="loadedLocal"><span class="months-min-width">{{calendarDateMonths}}</span>{{calendarDateYear}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-timeline-wrapper type-2">
      <full-calendar
        ref="fullCalendar"
        :options='calendarOptions'
      >
        <template v-slot:resourceAreaHeaderContent>
          <div class="row justify-content-between align-items-center nowrap">
            <div class="col-auto"><div class="color-grey text-md">Name task</div></div>
          </div>
        </template>
        <template v-slot:slotLabelContent="arg">
          <div>
            <div class="day-week">{{dateFormat(arg.date).dayWeek}}</div>
            <div class="day-month">{{dateFormat(arg.date).day}}</div>
          </div>
        </template>
        <template v-slot:eventContent='arg'>
          <div class="evetn-content">
            <div class="title">
              {{arg.event.title}}
            </div>
            <!--
            <div class="user">
              <span class="image">
                <img v-if="arg.event.extendedProps.urlImageUser" :src="arg.event.extendedProps.urlImageUser" alt="image">
                <img v-else src="@/assets/img/default-user-avatar.png" alt="image">
              </span>
              <div>
                <div class="name">{{arg.event.extendedProps.nameUser}}</div>
                <div class="prof">{{arg.event.extendedProps.profUser}}</div>
              </div>
            </div>
            -->
          </div>
        </template>
      </full-calendar>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
export default {
  props: {
    plan: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      showModal: false,
      loadedLocal: false
    }
  },
  computed: {
    calendarOptions () {
      const r = []
      this.plan.forEach((e) => {
        r.push({
          id: e.resourceId,
          title: e.title,
          eventColor: e.color
        })
      })
      return {
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        plugins: [
          resourceTimelinePlugin
        ],
        headerToolbar: false,
        initialView: 'resourceTimelineMonth',
        weekNumberFormat: {
          week: 'short'
        },
        resourcesInitiallyExpanded: false,
        slotMinWidth: 45,
        resourceAreaWidth: '24%',
        editable: false,
        contentHeight: 'auto',
        handleWindowResize: false,
        nowIndicator: true,
        resources: r,
        events: this.plan,
        eventsSet: this.handleEvents,
        eventClick: this.openEditTask
      }
    },
    calendarApi () {
      return this.$refs.fullCalendar.getApi()
    },
    calendarDateMonths () {
      return this.getNameMonth(this.calendarApi.getDate().getMonth())
    },
    calendarDateYear () {
      return this.calendarApi.getDate().getFullYear()
    }
  },
  methods: {
    scrollHgCalendar: function () {
      const $e = document.querySelector('.app-timeline-wrapper.type-2')
      const t = $e.getBoundingClientRect().top + pageYOffset
      const wh = window.innerHeight
      this.calendarApi.setOption('height', wh - (t + 50))
    },
    handleEvents () {
      this.loadedLocal = true
      this.scrollHgCalendar()
      this.calendarCurrentS = this.calendarApi.view.currentStart
      this.calendarCurrentE = this.calendarApi.view.currentEnd
    },
    dateFormat (e) {
      const t = new Date(e)
      var date = t.getFullYear() + '-' + (t.getMonth()) + '-' + t.getDate()
      return {
        date: date,
        day: t.getDate(),
        dayWeek: this.getNameDayWeek(t.getDay()),
        month: t.getMonth(),
        year: t.getFullYear(),
        nameMonth: this.getNameMonth(t.getMonth())
      }
    },
    getNameMonth (e) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[e]
    },
    getNameDayWeek (e) {
      const months = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return months[e]
    }
  },
  components: {
    FullCalendar
  }
}
</script>

<style lang="scss">
.app-timeline-wrapper.type-2{
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 16px;
}
.app-timeline-wrapper.type-2{
  .fc .fc-timeline-now-indicator-arrow{
    display: none;
  }
  .fc .fc-timeline-now-indicator-line{
    border-width: 0 0 0 2px;
    transform: translateX(23px);
    border-color: #FA665B;
    &:after{
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background-color: #FA665B;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-5px);
    }
  }
  .fc-timeline-event{
    border-radius: 6px;
    height: 28px;
    position: relative;
    bottom: -5px!important;
    padding: 4px 8px;
  }
  .fc-event-main{
    > span{
      display: block;
      width: 100%;
      line-height: 1.2em;
    }
  }
  .evetn-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .title{
      color: #161616;
      font-size: 14px;
    }
    .user{
      display: flex;
      align-items: center;
      line-height: 1em;
    }
    .image{
      display: inline-block;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border-radius: 50%;
      margin-right: 8px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name{
      color: #161616;
      font-size: 12px;
      margin-bottom: 1px;
    }
    .prof{
      color: #767676;
      font-size: 12px;
    }
  }
  .day-week{
    color: #767676;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2px;
    font-weight: 400;
  }
  .day-month{
    width: 22px;
    height: 22px;
    margin: 0 auto;
    border-radius: 8px;
    text-align: center;
    line-height: 23px;
    font-size: 12px;
    font-weight: 400;
    color: #161616;
  }
  .fc-timeline-slot-label.fc-day-today{
    .day-month{
      background: #FA665B;
      color: #FFFFFF;
    }
  }
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  .fc-scrollgrid-section-header,
  .fc-scrollgrid-section-body{
    > td:last-of-type{
      border-right: 0;
    }
  }
  .fc-scrollgrid-section-body{
    > td:first-of-type .fc-scroller.fc-scroller-liquid-absolute{
      overflow: hidden!important;
    }
  }
  .fc-resource-timeline-divider.fc-cell-shaded{
    opacity: 0;
    visibility: hidden;
    width: 0;
  }
  .fc-datagrid-cell-frame{
    display: flex;
    align-items: center;
  }
  .fc-scroller-harness{
    .fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner{
      width: 100%;
    }
  }
  .fc-timeline-header {
    .fc-timeline-slot{
      border-left: 0;
    }
  }
  .fc-datagrid-cell-main{
    font-size: 14px;
    color: #161616;
  }
  .fc-datagrid-cell-cushion{
    padding: 0;
  }
}

</style>
