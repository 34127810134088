<template>
  <div>
    <pop-up
      :show-modal="showModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-three"
      @closemodal="$emit('closemodal')"
    >
      <div class="h2 color-dark lb-mb-32">Client</div>
      <div v-if="!loading_content">
        <template v-if="info_client">
          <ul class="tabs-nav type-two">
            <li @click.prevent="setActive('tab-1')" :class="{ 'is-active': isActive('tab-1') }">Profile</li>
            <li @click.prevent="setActive('tab-2')" :class="{ 'is-active': isActive('tab-2') }">Roadmap</li>
          </ul>
          <div class="tabs-container lb-tp-25" v-if="isActive('tab-1')" :class="{ 'is-active': isActive('tab-1') }">
            <div v-if="info_client.profile" class="lb-mb-20">
              <div class="h3 lb-mb-2">Main information</div>
              <div class="color-grey lb-mb-15">Information about the chosen profession.</div>
              <div class="more-info-layout">
                <div class="info-user-wrapper">
                  <div class="info">
                    <div class="h2 lb-mb-2">{{info_client.profile.fullname}}</div>
                    <div v-if="info_client.profile.current_job" class="lb-mb-15">{{info_client.profile.current_job}}</div>
                    <table class="table table-mod text-md">
                      <tbody>
                      <tr v-if="info_client.profile.email">
                        <td>E-mail</td>
                        <td><span class="text-media color-dark">{{info_client.profile.email}}</span></td>
                      </tr>
                      <tr v-if="info_client.profile.phone">
                        <td>Phone number</td>
                        <td><span class="text-media color-dark">{{info_client.profile.phone}}</span></td>
                      </tr>
                      <tr v-if="info_client.profile.location">
                        <td>Location</td>
                        <td><span class="text-media color-dark">{{info_client.profile.location}}</span></td>
                      </tr>
                      <tr v-if="info_client.profile.lin">
                        <td>LinkedIn</td>
                        <td><a :href="info_client.profile.lin" class="btn btn-link">{{info_client.profile.lin}}</a></td>
                      </tr>
                      <tr v-if="info_client.profile.tw">
                        <td>Twitter</td>
                        <td><a :href="info_client.profile.tw" class="btn btn-link">{{info_client.profile.tw}}</a></td>
                      </tr>
                      <tr v-if="info_client.profile.fb">
                        <td>Facebook</td>
                        <td><a :href="info_client.profile.fb" class="btn btn-link">{{profile.user_data.fb}}</a></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="image">
                    <img v-if="info_client.profile.photo && checkURL(info_client.profile.photo)" :src="info_client.profile.photo" alt="image">
                    <img v-else src="@/assets/img/default-user-avatar.png" alt="iamge">
                  </div>
                </div>
              </div>
            </div>
            <div v-if="info_client.information" class="lb-mb-20">
              <div class="h3 lb-mb-15">Information</div>
              <div class="more-info-layout">
                <table class="table table-mod min-first-col text-md">
                  <tbody>
                  <tr
                    v-for="(item, i) in info_client.information"
                    :key="i + 's'"
                  >
                    <td>{{item.title}}</td>
                    <td>
                  <span class="text-media color-dark">
                    <span
                      class="info-list-c"
                      v-for="(option, a) in item.options.filter(e => e.selected === true)"
                      :key="a + 'c'"
                    >
                      {{option.value}}<span>,</span>
                    </span>
                  </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="info_client.idealjob" class="lb-mb-20">
              <div class="h3 lb-mb-2">Ideal Job</div>
              <div class="color-grey lb-mb-15">Information about the chosen profession.</div>
              <div class="more-info-layout">
                <table class="table table-mod text-md">
                  <tbody>
                  <tr v-if="info_client.idealjob.position">
                    <td>Desired Position</td>
                    <td><span class="text-media color-dark">{{info_client.idealjob.position}}</span></td>
                  </tr>
                  <tr v-if="info_client.idealjob.salary_year || info_client.idealjob.salary_hour">
                    <td>Desired Salary</td>
                    <td>
                      <span class="text-media color-dark">
                        <span v-if="info_client.idealjob.salary_year">${{info_client.idealjob.salary_year}} / year • </span><span v-if="info_client.idealjob.salary_hour">${{info_client.idealjob.salary_hour}} / hr</span>
                      </span>
                    </td>
                  </tr>
                  <tr v-if="info_client.idealjob.relocate">
                    <td>Willing to Relocate</td>
                    <td><span class="text-media color-dark">{{info_client.idealjob.relocate}}</span></td>
                  </tr>
                  <tr v-if="info_client.idealjob.travel">
                    <td>Willing to Travel</td>
                    <td><span class="text-media color-dark">{{info_client.idealjob.travel}}</span></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="info_client.credentials" class="lb-mb-20">
              <div class="h3 lb-mb-2">Professional Credentials</div>
              <div class="color-grey lb-mb-15">Information about the chosen profession.</div>
              <div class="more-info-layout">
                <table class="table table-mod text-md">
                  <tbody>
                  <tr v-if="info_client.credentials.experience">
                    <td>Experience</td>
                    <td><span class="text-media color-dark">{{info_client.credentials.experience}} years</span></td>
                  </tr>
                  <tr v-if="info_client.credentials.autorization">
                    <td>Work Autorization</td>
                    <td><span class="text-media color-dark">{{info_client.credentials.autorization}}</span></td>
                  </tr>
                  <tr v-if="info_client.credentials.security">
                    <td>Security Clearance</td>
                    <td><span class="text-media color-dark">{{info_client.credentials.security}}</span></td>
                  </tr>
                  <tr v-if="info_client.credentials.relocate">
                    <td>Willing to Relocate</td>
                    <td><span class="text-media color-dark">{{info_client.credentials.relocate}}</span></td>
                  </tr>
                  <tr v-if="info_client.credentials.cv_name">
                    <td>Resume</td>
                    <td>
                      <a
                        :href="info_client.credentials.cv_url"
                        class="btn btn-link"
                      >
                        <span class="icon"><img src="@/assets/img/icons/icon_drive_file.svg" alt="icon"></span>
                        <span>{{info_client.credentials.cv_name}}</span>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="info_client.credentials.cover_name">
                    <td>Cover Letter</td>
                    <td>
                      <a
                        :href="info_client.credentials.cover_url"
                        class="btn btn-link"
                      >
                        <span class="icon"><img src="@/assets/img/icons/icon_drive_file.svg" alt="icon"></span>
                        <span>{{info_client.credentials.cover_name}}</span>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="tabs-container lb-tp-25" v-if="isActive('tab-2')" :class="{ 'is-active': isActive('tab-2') }">
            <div class="lb-mb-8">
              <template v-if="roadmap">
                <timeline-statistics
                  :plan="roadmap"
                ></timeline-statistics>
              </template>
            </div>
            <div class="text-sm color-grey">Roadmap completed: <span class="color-green2">56%</span></div>
          </div>
        </template>
      </div>
      <div v-else class="loader-wrapper">
        <div class="loader-circle">
          <img src="@/assets/img/loader-icon.png" alt="loader">
        </div>
      </div>
    </pop-up>
  </div>
</template>
<script>
import PopUp from '@/components/PopUp'
import TimelineStatistics from '@/components/TimelineStatistics'
export default {
  props: {
    showModal: {
      type: Boolean,
      default: true
    },
    clientId: {
      default: null
    }
  },
  data () {
    return {
      activeItem: 'tab-1',
      loading_client: false,
      loading_plan: false
    }
  },
  computed: {
    loading_content: function () {
      if (!this.loading_client && !this.loading_plan) {
        return false
      } else {
        return true
      }
    },
    roadmap: function () {
      return this.$store.getters.roadmap
    },
    info_client: function () {
      return this.$store.getters.info_client_one
    }
  },
  methods: {
    isActive (e) {
      return this.activeItem === e
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    checkURL: function (url) {
      return (url.match(/\.(jpeg|jpg|gif|png)$/) != null)
    }
  },
  watch: {
    showModal: {
      handler (val) {
        if (val === true && this.clientId) {
          this.loading_client = true
          this.$store.dispatch('fetchClientOne', this.clientId)
            .finally(() => {
              this.loading_client = false
            })
          this.loading_plan = true
          this.$store.dispatch('fetchRoadmap', this.clientId)
            .finally(() => {
              this.loading_plan = false
            })
        }
      },
      deep: true
    }
  },
  mounted () {
  },
  components: {
    PopUp,
    TimelineStatistics
  }
}
</script>

<style scoped lang="scss">
.info-list-c:last-child{
  span{
    display: none;
  }
}
.more-info-layout{
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 16px;
}
.table.table-mod{
  tr:first-child{
    td{
      padding-top: 0;
    }
  }
  tr:last-child{
    td{
      padding-bottom: 0;
    }
  }
}
.info-user-wrapper{
  display: flex;
  .info{
    width: 100%;
    padding-right: 40px;
  }
  .image{
    width: 120px;
    height: 120px;
    flex: 0 0 120px;
    border-radius: 8px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
</style>
