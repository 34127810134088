<template>
  <div>
    <header class="header bg-white br-bottom">
      <div class="header-logo h2">Plan Schedule</div>
      <div class="header-rg">
        <div class="row row-12 justify-content-between align-items-center">
          <div class="col-auto">
            <div class="input-wrapper-icon autocomplete-two-wrap clean">
              <div class="input-inner">
                <span class="icon"><img src="@/assets/img/icons/icon-search-grey-01.svg" alt="icon-search"></span>
                <button
                  class="icon-clean"
                  v-if="searchEventsStr.length"
                  @click="searchEventsStr = ''"
                >
                  <img src="@/assets/img/icons/icon-clear.svg" alt="icon-search">
                </button>
                <input class="input sm" placeholder="Search events" type="text" v-model="searchEventsStr">
              </div>
              <div v-if="searchEvents.length > 0" class="autocomplete-two-inner">
                <div v-for="(item, i) in searchEvents" :key="i">
                  <div class="atc-list-item lb-mb-4" @click="calendarApi.gotoDate(item.startStr)">
                    <div class="color-dark lb-mb-2 text-md max-l-a">
                      {{item.title}}
                      <sapn v-if="item.extendedProps.nameUser">{{item.extendedProps.nameUser}}</sapn>
                    </div>
                    <div class="color-grey text-sm max-l-a">{{dateFormat(item.startStr)}}</div>
                  </div>
                  <hr v-if="searchEvents.length - 1 > i" class="hr lb-mb-4">
                </div>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <button class="btn btn-ghost btn-icon lb-mr-8"><img src="@/assets/img/icons/iocn-flash_on.svg" alt="icon"></button>
            <button class="btn btn-ghost btn-icon"><img src="@/assets/img/icons/icon-settings.svg" alt="icon"></button>
          </div>
        </div>
      </div>
    </header>
    <div class="row row-32">
      <div class="col-lg-9">
        <div class="block-layout height-js">
          <div>
            <div>
              <div class="lb-mb-32">
                <div class="row align-items-center justify-content-between">
                  <div class="col-auto">
                    <div class="h2 color-dark">Calendar</div>
                  </div>
                  <div class="col-auto">
                    <div class="row row-8 align-items-center">
                      <div class="col-auto">
                        <div class="btn-group no-bg">
                          <button @click="calendarApi.prev(); changeDate()" class="btn btn-ghost btn-icon"><img src="@/assets/img/icons/icon-keyboard_arrow_left.svg" alt="icon"></button>
                          <button @click="calendarApi.next(); changeDate()" class="btn btn-ghost btn-icon"><img src="@/assets/img/icons/icon-keyboard_arrow_right.svg" alt="icon"></button>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="h4 lb-mr-26" v-if="loadedLocal"><span class="months-min-width">{{calendarDateMonths}}</span>{{calendarDateYear}}</div>
                      </div>
                      <div class="col-auto">
                        <div class="dropdown drop-select min-a">
                          <span class="dropdown-overlay" @click="dropClose"></span>
                          <button class="btn btn-outlined" @click="dropOpen">Week</button>
                          <ul class="dropdown-inner">
                            <li @click="msDrop('timeGridWeek', $event); changeDate()" :class="{ 'is-active': isDrop('timeGridWeek') }">Week</li>
                            <li @click="msDrop('timeGridDay', $event); changeDate()" :class="{ 'is-active': isDrop('timeGridDay') }">Day</li>
                            <li @click="msDrop('dayGridMonth', $event); changeDate()" :class="{ 'is-active': isDrop('dayGridMonth') }">Month</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="app-calendar-wrapper">
                <div v-if="loadingEvents" class="app-calendar-loader">
                  <div class="loader-circle">
                    <img src="@/assets/img/loader-icon.png" alt="loader">
                  </div>
                </div>
                <FullCalendar
                  class='demo-app-calendar'
                  ref="fullCalendar"
                  :options='calendarOptions'
                >
                  <template v-slot:dayHeaderContent="arg">
                    <div>
                      <div class="day-week">{{dateDay(arg.date).dayWeek}}</div>
                      <div class="day-month">{{dateDay(arg.date).day}}</div>
                    </div>
                  </template>
                  <template v-slot:eventContent='arg'>
                    <div class="title">{{ arg.event.title }}</div>
                    <div class="time">{{ arg.timeText }}</div>
                  </template>
                </FullCalendar>
              </div>
              <div
                class="fc-v-event-wrapper"
                ref="eventpopup"
                :class="{'is-active': eventPopUp}"
              >
                <div>
                  <div class="fc-v-event-overlay" @click="closeEventInfo"></div>
                  <div class="fc-v-event-popup">
                    <div class="btn-wrapper right">
                      <button class="btn btn-ghost btn-icon" @click="openEventEdit(eventPopUpSingl.id)"><img src="@/assets/img/icons/icon-pen-edit.svg" alt="icon"></button>
                      <button class="btn btn-ghost btn-icon"><img src="@/assets/img/icons/icon-more_horiz.svg" alt="icon"></button>
                      <button class="btn btn-ghost btn-icon" @click="closeEventInfo"><img src="@/assets/img/icons/icon-close.svg" alt="icon"></button>
                    </div>
                    <div class="fc-v-event-body">
                      <div v-if="eventPopUpSingl">
                        <div class="lb-mb-4 h3">{{eventPopUpSingl.title}}</div>
                        <div v-if="eventPopUpSingl.description" class="lb-mb-15">{{eventPopUpSingl.description}}</div>
                        <div class="fc-v-event-list">
                          <span class="icon"><img src="@/assets/img/icons/icon-calendar_today.svg" alt="icon"></span>
                          <span class="color-dark">{{dateDay(eventPopUpSingl.date).dayWeek}}, {{dateDay(eventPopUpSingl.date).day}} {{dateDay(eventPopUpSingl.date).nameMonth}} </span>
                          - <span class="color-dark"> {{dateFormat('2021-08-02T' + eventPopUpSingl.start)}} <span v-if="eventPopUpSingl.end">– {{dateFormat('2021-08-02T' + eventPopUpSingl.end)}}</span></span>
                        </div>
                        <div v-if="eventPopUpSingl.client_id" class="fc-v-event-list">
                          <span class="icon"><img src="@/assets/img/icons/icon-person_pin.svg" alt="icon"></span>
                          <div v-if="one_client(eventPopUpSingl.client_id)" class="fc-v-event-list-user">
                            <span class="image-wr">
                              <span class="image">
                                <img v-if="one_client(eventPopUpSingl.client_id).user_data.photo && checkURL(one_client(eventPopUpSingl.client_id).user_data.photo)" :src="one_client(eventPopUpSingl.client_id).user_data.photo" alt="image">
                                <img src="@/assets/img/default-user-avatar.png" alt="image">
                              </span>
                            </span>
                            <span class="name color-dark">{{one_client(eventPopUpSingl.client_id).user_data.fullname}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="block-layout height-js">
          <div class="title-wrapper-bt lb-mb-20">
            <div class="h2 title">Upcoming events</div>
            <div class="bt-wrapper"><button class="btn btn-outlined" @click="openEventAdd">Add</button></div>
          </div>
          <div v-if="!loadingEvents && !loading_clients">
            <div v-if="eventsFilterDay.length > 0">
              <div v-for="(day, a) in eventsFilterDay" :key="a" class="lb-mb-20">
                <div class="color-grey lb-mb-15">{{day.day}} {{day.month}}</div>
                <div  v-for='(event, i) in day.events' :key='i' class="event-item">
                  <div class="content">
                    <div class="color-dark lb-mb-2">
                      {{event.title}}
                      <button
                        v-if="event.extendedProps.client_id" class="btn btn-link"
                        @click="openInfoUser(event.extendedProps.client_id)"
                      >
                        {{one_client(event.extendedProps.client_id).user_data.fullname}}
                      </button>
                    </div>
                    <div class="color-grey text-sm">{{dateFormat(event.startStr)}}</div>
                  </div>
                  <div v-if="event.extendedProps.client_id" class="image">
                    <img v-if="one_client(event.extendedProps.client_id).user_data.photo && checkURL(one_client(event.extendedProps.client_id).user_data.photo)" :src="one_client(event.extendedProps.client_id).user_data.photo" alt="image">
                    <img v-else src="@/assets/img/default-user-avatar.png" alt="image">
                  </div>
                </div>
              </div>
            </div>
            <div v-else>None</div>
          </div>
          <div v-else class="loader-wrapper">
            <div class="loader-circle">
              <img src="@/assets/img/loader-icon.png" alt="loader">
            </div>
          </div>
        </div>
      </div>
    </div>
    <pop-up
      :show-modal="showModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-three"
      @closemodal="closeEventEdit"
    >
      <div v-if="!isEdit" class="h2 color-dark lb-mb-32">Create a new event</div>
      <div v-else class="h2 color-dark lb-mb-32">Edit</div>
      <div v-if="events_edit">
        <div class="lb-mb-15">
          <div class="h4 lb-mb-2">General information</div>
          <div class="text-md">Fill in the required fields to describe the event</div>
        </div>
        <div class="form-group md lb-mb-20">
          <div
            class="input-wrapper-material focus-st"
            :class="{'typed': events_edit.title}"
          >
            <div class="input-inner">
              <input
                class="input input-material"
                name="name"
                type="text"
                @focus="focusInput"
                @blur="blurInput($event); $v.events_edit.title.$touch()"
                @input="typedInput"
                @change="typedInput"
                v-model="events_edit.title"
                :class="{'error': $v.events_edit.title.$error}"
              >
              <label>Task title</label>
            </div>
            <div v-if="$v.events_edit.title.$error" class="input-error">Error</div>
          </div>
          <div
            class="input-wrapper-material textarea focus-st"
            :class="{'typed': events_edit.description}"
          >
            <div class="input-inner">
                <textarea
                  class="input input-material tx-md typed"
                  @focus="focusInput"
                  @blur="blurInput($event)"
                  @input="typedInput"
                  v-model="events_edit.description"
                ></textarea>
              <label>Description</label>
            </div>
          </div>
        </div>
        <div class="lb-mb-15">
          <div class="h4 lb-mb-2">Set event date</div>
          <div class="text-md">Specify the date and time of the event</div>
        </div>
        <!--
        <vc-date-picker class="v-calendar_own" :min-date="new Date()" is-range />
        -->
        <div class="date-in-wrapper lb-mb-20">
          <div
            class="input-wrapper-material date-in-date focus-st"
          >
            <!--  :class="{'typed': events_edit.event_date}"  -->
            <vc-date-picker
              class="v-calendar_own"
              v-model="events_edit.event_date"
              :min-date="new Date()"
              is24hr
              is-required
              :locale="{ id: 'en', masks: { weekdays: 'WW' } }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <div class="input-inner">
                  <input
                    class="input input-typed input-material"
                    type="text"
                    @focus="focusInput"
                    @blur="blurInput($event); $v.events_edit.event_date.$touch()"
                    @input="typedInput"
                    @change="typedInput"
                    :value="inputValue ? inputValue + ' ' + new Date().toString().match(/([A-Z]+[\+-][0-9]+.[0-9])/)[1] : inputValue"
                    v-on="inputEvents"
                    :class="{'typed': inputValue, 'error': $v.events_edit.event_date.$error}"
                  >
                  <label>Set date</label>
                </div>
              </template>
            </vc-date-picker>
          </div>
          <div class="drop-search type-2 date-in-time">
            <div
              class="input-wrapper-material focus-st drop-search-input tp-not"
              :class="{'typed': events_edit.t_start}"
            >
              <div class="input-inner">
                <input
                  class="input input-material"
                  type="text"
                  readonly="readonly"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.events_edit.t_start.$touch()"
                  @input="typedInput"
                  @change="typedInput"
                  v-model="events_edit.t_start"
                  :class="{'error': $v.events_edit.t_start.$error}"
                >
                <label>Time from</label>
              </div>
            </div>
            <div v-if="timeArray.length > 0 && events_edit.event_date" class="drop-search-content">
              <ul class="list">
                <li
                  v-for="(time_start, s) in timeArray('00:00:00')"
                  :key="s + 'xc'"
                  :data-st="dateDay(events_edit.event_date).date"
                  :data-rt="dateDay(new Date()).date"
                  :class="{'is-active': events_edit.t_start === time_start,
                           'disabled': dateChange(new Date(), time_start) > dateChange(new Date(), events_edit.t_end) ||
                                       dateDay(events_edit.event_date).date === dateDay(new Date()).date && dateChange(new Date(), time_start) < dateChange(new Date(), new Date()) }"
                  @click="events_edit.t_start = time_start; inputEvent = time_start"
                >
                  <span>{{dateFormat('2021-08-02T' + time_start)}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="date-in-sp color-grey-2">—</div>
          <div class="drop-search type-2 date-in-time">
            <div
              class="input-wrapper-material focus-st drop-search-input tp-not"
              :class="{'typed': events_edit.t_end}"
            >
              <div class="input-inner">
                <input
                  class="input input-material"
                  type="text"
                  readonly="readonly"
                  @focus="focusInput"
                  @blur="blurInput($event); $v.events_edit.t_end.$touch()"
                  @input="typedInput"
                  @change="typedInput"
                  v-model="events_edit.t_end"
                  :class="{'error': $v.events_edit.t_end.$error}"
                >
                <label>Time to</label>
              </div>
            </div>
            <div v-if="timeArray.length > 0" class="drop-search-content">
              <ul class="list">
                <li
                  v-for="(time_end, z) in timeArray('00:00:00')"
                  :key="z + 'sk'"
                  :class="{'is-active': events_edit.t_end === time_end, 'disabled': dateChange(new Date(), time_end) < dateChange(new Date(), events_edit.t_start)}"
                  @click="events_edit.t_end = time_end; inputEvent = time_end"
                >
                  <span>{{dateFormat('2021-08-02T' + time_end)}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="lb-mb-15">
          <div class="h4 lb-mb-2">Assign to</div>
          <div class="text-md">Schedule your clients for a meeting</div>
        </div>
        <div class="drop-search lb-mb-4">
          <div class="input-wrapper-material focus-st drop-search-input">
            <div class="input-inner">
              <input
                class="input input-material"
                type="text"
                @focus="focusInput"
                @blur="blurInput"
                @input="typedInput"
                @change="typedInput"
                v-model="clientsSearch"
              >
              <label>Add clients</label>
            </div>
            <div class="icon"><img src="@/assets/img/icons/ic-arrow_drop_down.svg" alt="icon"></div>
          </div>
          <div v-if="resultClients.length > 0" class="drop-search-content">
            <ul class="list">
              <li
                v-for="(item, i) in resultClients"
                :key="i + 'l'"
                @click="add_clients(item)"
              >
                <span v-if="item.user_data" class="user-list-item">
                  <span class="image">
                    <img v-if="item.user_data.photo && checkURL(item.user_data.photo)" :src="item.user_data.photo" alt="image">
                    <img v-else src="@/assets/img/default-user-avatar.png" alt="image-user">
                  </span>
                  <span class="info text-md">
                    <span class="name color-dark">{{item.user_data.fullname}}</span>
                    <span class="prof">{{item.user_data.current_job}}</span>
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="lb-mb-40">
          <div
            class="user-list-item h-pd"
            v-if="events_edit.client_id"
          >
            <span class="image">
              <img v-if="one_client(events_edit.client_id).user_data.photo && checkURL(one_client(events_edit.client_id).user_data.photo)" :src="one_client(events_edit.client_id).user_data.photo" alt="image">
              <img v-else src="@/assets/img/default-user-avatar.png" alt="image-user">
            </span>
            <span class="info text-md">
              <span class="name color-dark">{{one_client(events_edit.client_id).user_data.fullname}}</span>
              <span class="prof">{{one_client(events_edit.client_id).user_data.current_job}}</span>
            </span>
            <button
              class="btn btn-ghost btn-icon"
              @click="events_edit.client_id = ''"
            >
              <img src="@/assets/img/icons/icon-close-dark.svg" alt="icon">
            </button>
          </div>
        </div>
        <div class="btn-wrapper">
          <button
            class="btn btn-primary btn-loader"
            :class="{'disable': $v.$invalid, 'loader': loadingSend}"
            @click="saveEvent"
            v-if="!isEdit"
          >
            Create task
          </button>
          <button
            class="btn btn-primary btn-loader"
            :class="{'disable': $v.$invalid, 'loader': loadingSend}"
            @click="saveEvent"
            v-else
          >
            Save
          </button>
          <button class="btn btn-secondary" @click="closeEventEdit">Cancel</button>
        </div>
      </div>
    </pop-up>
    <popup-info-profile
      :show-modal="showPopupUserInfo"
      :client-id="clientId"
      @closemodal="closeInfoUser"
    ></popup-info-profile>
    <input style="display: none;" v-model="inputEvent">
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { required } from 'vuelidate/lib/validators'
import PopUp from '@/components/PopUp'
import PopupInfoProfile from '@/components/PopupInfoProfile'
export default {
  data () {
    return {
      dateActive: '',
      calendar_mounted: false,
      inputEvent: '',
      clients: [],
      loading_clients: true,
      clientsSearch: '',
      loadingSend: false,
      showModal: false,
      events_edit: '',
      events_model: {
        id: new Date().getTime(),
        title: '',
        description: '',
        event_date: new Date(),
        t_start: '00:00:00',
        t_end: '01:00:00',
        client_id: ''
      },
      isEdit: false,
      eventPopUp: false,
      eventPopUpSingl: '',
      activeDrop: 'timeGridWeek',
      searchEventsStr: '',
      loadedLocal: false,
      calendarCurrentS: '',
      calendarCurrentE: '',
      currentEvents: [],
      loadingEvents: true,
      showPopupUserInfo: false,
      clientId: null
    }
  },
  computed: {
    resultClients () {
      return this.clients.filter(v => {
        if (this.clientsSearch === '') {
          return true
        } else {
          return v.user_data.fullname.toLowerCase().includes(this.clientsSearch.toLowerCase())
        }
      })
    },
    events () {
      return this.$store.getters.events
    },
    calendarOptions () {
      const e = []
      if (this.events.length > 0) {
        this.events.forEach((x) => {
          e.push({
            id: x.id,
            title: x.title,
            description: x.description,
            start: this.dateDay(x.event_date).date + 'T' + x.t_start,
            end: this.dateDay(x.event_date).date + 'T' + x.t_end,
            t_date: x.event_date,
            t_start: x.t_start,
            t_end: x.t_end,
            client_id: x.client_id
          })
        })
      }
      return {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin
        ],
        headerToolbar: false,
        initialView: 'timeGridWeek',
        weekNumberFormat: {
          week: 'short'
        },
        allDaySlot: false,
        nowIndicator: true,
        events: e,
        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventsSet: this.handleEvents,
        eventClick: this.openEventInfo,
        dateClick: this.openEventAdd_day
      }
    },
    calendarApi () {
      return this.$refs.fullCalendar.getApi()
    },
    calendarDateMonths () {
      return this.getNameMonth(this.calendarApi.getDate().getMonth())
    },
    calendarDateYear () {
      return this.calendarApi.getDate().getFullYear()
    },
    currentEventsFilter () {
      const d = this.currentEvents.filter((e) => {
        const v = new Date(e.startStr)
        const t = new Date()
        return v >= t
      })
      return d
    },
    eventsFilterDay () {
      const d = []
      const v = []
      this.currentEventsFilter.forEach((e) => {
        const t = this.dateDay(e.startStr)
        if (d.length > 0) {
          const z = v.indexOf(t.date)
          if (z >= 0) {
            d[z].events.push(e)
          } else {
            d.push({
              date: t.date,
              month: t.nameMonth,
              day: t.day,
              events: []
            })
            d[d.length - 1].events.push(e)
            v.push(t.date)
          }
        } else {
          d.push({
            date: t.date,
            month: t.nameMonth,
            day: t.day,
            events: []
          })
          d[0].events.push(e)
          v.push(t.date)
        }
      })
      return d
    },
    searchEvents () {
      return this.currentEvents.filter(val => {
        if (this.searchEventsStr === '') {
          return false
        } else {
          const a = val.title + val.extendedProps.nameUser
          return a.toLowerCase().includes(this.searchEventsStr.toLowerCase())
        }
      })
    },
    userSecurity () {
      return this.$store.getters.userSecurity
    }
  },
  methods: {
    console_log (e) {
      console.log(e)
      return 'd'
    },
    openInfoUser (id) {
      this.clientId = id
      this.showPopupUserInfo = true
      this.$utils.blockScroll()
    },
    closeInfoUser () {
      this.showPopupUserInfo = false
      this.$utils.unBlockScroll()
    },
    one_client (id) {
      return this.clients.find((e) => {
        return +e.client_id === +id
      })
    },
    timeArray (e) {
      const t = []
      const d = new Date('2021-08-02T' + e + '+03:00')
      const h = d.getHours()
      const m = d.getMinutes()
      // const md = ['AM', 'PM']
      for (var i = h; i < 24; ++i) {
        for (var j = i === h ? Math.ceil(m / 15) : 0; j < 4; ++j) {
          // t.push(i % 12 + ':' + (j * 15 || '00') + ' ' + md[i / 12 | 0])
          t.push(i % 24 <= 9 ? '0' + i % 24 + ':' + (j * 15 || '00') + ':00' : i % 24 + ':' + (j * 15 || '00') + ':00')
        }
      }
      return t
    },
    focusInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.add('focus')
    },
    blurInput: function (e) {
      e.target.closest('.input-wrapper-material').classList.remove('focus')
    },
    typedInput: function (e) {
      if (e.target.value) {
        e.target.closest('.input-wrapper-material').classList.add('typed')
      } else {
        e.target.closest('.input-wrapper-material').classList.remove('typed')
      }
    },
    isDrop (e) {
      return this.activeDrop === e
    },
    msDrop (c, e) {
      const $wrap = e.target.closest('.dropdown')
      const t = e.target.innerHTML
      $wrap.classList.remove('is-active')
      $wrap.querySelector('.btn').innerText = t
      this.activeDrop = c
      this.calendarApi.changeView(c)
    },
    dropOpen: function (e) {
      e.target.closest('.dropdown').classList.add('is-active')
    },
    dropClose: function (e) {
      e.target.closest('.dropdown').classList.remove('is-active')
    },
    scrollHgCalendar: function () {
      const $e = document.querySelector('.app-calendar-wrapper')
      if ($e) {
        const t = $e.getBoundingClientRect().top + pageYOffset
        const wh = window.innerHeight
        this.calendarApi.setOption('height', wh - (t + 50))
      }
    },
    handleEvents (events) {
      this.currentEvents = events
      this.loadedLocal = true
      this.scrollHgCalendar()
      this.calendarCurrentS = this.calendarApi.view.currentStart
      this.calendarCurrentE = this.calendarApi.view.currentEnd
    },
    dateFormat (e) {
      const c = new Date(e)
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
      const t = new Intl.DateTimeFormat('en-US', options).format(c)
      return t
    },
    dateFormatHM (e) {
      const c = new Date(e)
      const t = ('0' + c.getHours()).slice(-2) + ':' + ('0' + c.getMinutes()).slice(-2) + ':' + ('0' + c.getSeconds()).slice(-2)
      return t
    },
    dateDay (e) {
      const t = new Date(e)
      const date = t.getFullYear() + '-' + ('0' + (t.getMonth() + 1)).slice(-2) + '-' + ('0' + t.getDate()).slice(-2)
      const d = {
        date: date,
        day: t.getDate(),
        dayWeek: this.getNameDayWeek(t.getDay()),
        year: t.getFullYear(),
        nameMonth: this.getNameMonth(t.getMonth()),
        hours: t.getHours(),
        minutes: t.getMinutes()
      }
      return d
    },
    dateChange (r, h) {
      let d
      if (isNaN(h) !== false) {
        d = new Date('2021-08-02T' + h + '+03:00')
      } else {
        d = h
      }
      r.setHours(d.getHours())
      r.setMinutes(d.getMinutes())
      r.setSeconds(d.getSeconds())
      return r
    },
    getNameMonth (e) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[e]
    },
    getNameDayWeek (e) {
      const months = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      return months[e]
    },
    openEventInfo (e) {
      const d = this.$refs.eventpopup.querySelector('.fc-v-event-popup')
      const s = e.el.getBoundingClientRect()
      d.style.top = s.top + 'px'
      d.style.left = s.left + s.width + 4 + 'px'
      this.eventPopUp = true
      const c = {
        id: e.event._def.publicId,
        title: e.event._def.title,
        description: e.event._def.extendedProps.description,
        start: e.event._def.extendedProps.t_start,
        end: e.event._def.extendedProps.t_end,
        date: e.event._def.extendedProps.t_date,
        client_id: e.event._def.extendedProps.client_id
      }
      this.eventPopUpSingl = c
    },
    closeEventInfo () {
      this.eventPopUp = false
      this.eventPopUpSingl = ''
    },
    openEventEdit (id) {
      this.isEdit = true
      const e = JSON.parse(JSON.stringify(this.$store.getters.oneEvent(id)))
      this.events_edit = e
      this.showModal = true
      this.closeEventInfo()
    },
    openEventAdd () {
      const d = new Date()
      const e = JSON.parse(JSON.stringify(this.events_model))
      this.isEdit = false
      // e.event_date = new Date()
      e.t_start = ('0' + (+d.getHours() + 1)).slice(-2) + ':00:00'
      e.t_end = ('0' + (+d.getHours() + 1)).slice(-2) + ':00:00'
      this.events_edit = e
      this.showModal = true
      this.closeEventInfo()
    },
    openEventAdd_day (d) {
      const today = new Date()
      const event = d.date
      if (today.getTime() < event.getTime()) {
        this.isEdit = false
        const e = JSON.parse(JSON.stringify(this.events_model))
        e.event_date = d.date
        e.t_start = this.dateFormatHM(d.date)
        e.t_end = this.dateFormatHM(d.date)
        this.events_edit = e
        this.showModal = true
      }
    },
    closeEventEdit () {
      this.showModal = false
    },
    saveEvent () {
      if (this.isEdit) {
        this.loadingSend = true
        this.$store.dispatch('sendEventsEdit', this.events_edit)
          .finally(() => {
            this.loadingSend = false
            this.showModal = false
          })
      } else {
        this.loadingSend = true
        this.$store.dispatch('sendEventsAdd', this.events_edit)
          .finally(() => {
            this.loadingSend = false
            this.showModal = false
          })
      }
    },
    add_clients (e) {
      this.events_edit.client_id = e.client_id
    },
    changeDate () {
      const c = {
        start: this.dateActive.startStr,
        end: this.dateActive.endStr
      }
      this.loadingEvents = true
      this.$store.dispatch('fetchEvents', c)
        .finally(() => {
          this.loadingEvents = false
        })
    },
    checkURL: function (url) {
      return (url.match(/\.(jpeg|jpg|gif|png)$/) != null)
    },
    computedDate: function (input, days, months, years) {
      return new Date(
        input.getFullYear() + years,
        input.getMonth() + months,
        Math.min(
          input.getDate() + days,
          new Date(input.getFullYear() + years, input.getMonth() + months + 1, 0).getDate()
        )
      )
    }
  },
  mounted () {
    this.$utils.scrollHG()
    window.addEventListener('resize', this.$utils.scrollHG)
    window.addEventListener('resize', this.scrollHgCalendar)
    this.loadingEvents = false
    this.$axios({
      method: 'get',
      url: this.$root.$data.baseURL + 'apicall/coach/clients',
      headers: {
        Authorization: 'Bearer ' + this.userSecurity.token,
        'X-Api-Key': this.userSecurity.key
      }
    })
      .then((r) => {
        this.clients = r.data
        this.loading_clients = false
      })
      .catch(function (e) {
        console.log(e.response)
        console.error(e)
      })
  },
  watch: {
    calendar_mounted: function (v) {
      if (this.calendar_mounted) {
        const c = {
          start: this.dateActive.startStr,
          end: this.dateActive.endStr
        }
        this.loadingEvents = true
        this.$store.dispatch('fetchEvents', c)
          .finally(() => {
            this.loadingEvents = false
          })
      }
    }
  },
  validations: {
    events_edit: {
      title: {
        required
      },
      event_date: {
        required
      },
      t_start: {
        required
      },
      t_end: {
        required
      },
      client_id: {
        required
      }
    }
  },
  components: {
    FullCalendar,
    PopUp,
    PopupInfoProfile
  }
}
</script>

<style lang="scss">
@import "../assets/scss/_v-calendar.scss";
@import "../assets/scss/_plan-schedule.scss";
.app-calendar{
  &-wrapper{
    position: relative;
  }
  &-loader{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 22;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader-circle{
      width: 38px;
    }
  }
}
</style>
